<template>
  <dashboard-chart
    class="monthly-planning-dashboard-chart-chain-cost-projection"
    :base-filter="baseFilter"
    :on-load="onLoad"
    :on-export="onExport"
  >
    <template v-slot:header="{ chart }">
      <div
        class="monthly-planning-dashboard-chart-chain-cost-projection__header"
      >
        <bc-btn
          v-if="showCostByMonth"
          icon
          x-small
          class="monthly-planning-dashboard-chart-chain-cost-projection__back-btn"
          @click="onCloseCostByMonth"
        >
          <bc-icon size="14" color="light-grey">fas fa-chevron-left</bc-icon>
        </bc-btn>

        {{ showCostByMonth ? chartByMonth.title : chart.title }}
      </div>
    </template>

    <template v-slot:chart="{ chart }">
      <template v-if="!showCostByMonth">
        <high-chart
          v-if="chart.chartInput"
          key="chain-cost-chart"
          new-layout
          :chart-input-data="chart.chartInput"
          :chart="chart"
          :is-stacked="false"
          @onChartDataClick="onSelectMonth"
        ></high-chart>
      </template>

      <template v-else>
        <high-chart
          v-if="chartByMonth.chartInput"
          key="chain-cost-by-month-chart"
          new-layout
          :chart-input-data="chartByMonth.chartInput"
          :chart="chartByMonth"
          :isStacked="false"
        ></high-chart>
      </template>
    </template>
  </dashboard-chart>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcBtn, BcIcon } from '@brain/core'
import moment from 'moment'

import HighChart from '../charts/HighChart.vue'
import { DashboardChart } from '../dashboards'
import service from '../../api/monthlyPlanningRevisedService'
import { DateFormat, useDateFormatter } from '../../utils/dateFormatter'

export default defineComponent({
  name: 'MonthlyPlanningDashboardChartChainCostProjection',
  components: {
    BcBtn,
    BcIcon,
    HighChart,
    DashboardChart
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formatDate: useDateFormatter(this),
      chartByMonth: {},
      showCostByMonth: false
    }
  },
  methods: {
    async loadCostProjectionByMonth(monthIndex) {
      const planningMonth = this.formatDate(
        moment(this.baseFilter.startDate).add(monthIndex, 'months'),
        DateFormat.ISODate
      )
      const chart = await service.getChainCostProjectionByMonth(
        this.chartByMonth,
        {
          ...this.baseFilter,
          planningMonth
        }
      )
      this.chartByMonth = chart
    },
    async onLoad(chartReference) {
      const chart = await service.getChainCostProjection(
        chartReference,
        this.baseFilter
      )
      return chart
    },
    async onExport(chartReference) {
      await service.getChainCostProjectionExcel(chartReference, this.baseFilter)
    },
    async onSelectMonth({ event }) {
      if (event?.point?.index === undefined) return
      await this.loadCostProjectionByMonth(event.point.index)
      this.showCostByMonth = true
    },
    onCloseCostByMonth() {
      this.showCostByMonth = false
    }
  }
})
</script>

<style lang="scss" scoped>
.monthly-planning-dashboard-chart-chain-cost-projection {
  &__header {
    display: flex;
  }

  &__back-btn {
    margin: -8px 10px -8px -8px;
  }
}
</style>
