<template>
  <cargill-section :layer="3" class="monthly-planning-dashboard-consolidated">
    <cargill-section-header color="#2B323F">
      {{ consolidated.title }}
    </cargill-section-header>
    <cargill-section-content>
      <bc-container class="monthly-planning-dashboard-consolidated__container">
        <bc-row dense>
          <bc-col
            v-for="kpi in consolidated.kpis"
            :key="kpi.title"
            cols="4"
            xl="2"
          >
            <dashboard-kpi :kpi="kpi"></dashboard-kpi>
          </bc-col>
        </bc-row>
      </bc-container>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import { DashboardKpi } from '../dashboards'

import service from '../../api/monthlyPlanningService'

export default defineComponent({
  name: 'MonthlyPlanningDashboardConsolidated',
  components: {
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    DashboardKpi
  },
  props: {
    scenario: {
      type: Object,
      required: true
    },
    unit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      consolidated: {},
      kpis: []
    }
  },
  mounted() {
    this.loadConsolidated()
  },
  methods: {
    async loadConsolidated() {
      this.consolidated = await service.getConsolidated({
        startDate: this.scenario.beginHorizon,
        endDate: this.scenario.endHorizon,
        unity: this.unit.value
      })
    }
  },
  watch: {
    unit() {
      this.loadConsolidated()
    }
  }
})
</script>

<style lang="scss" scoped>
.monthly-planning-dashboard-consolidated {
  &__container {
    /* remove padding from bc-container */
    padding: 10px 0 10px 5px;
    ::v-deep .row {
      max-width: calc(100% + 5px);
      margin-right: 0;
    }
  }
}
</style>
