<template>
  <dashboard-chart
    class="monthly-planning-dashboard-chart-demand-not-fullfiled-by-region"
    :base-filter="baseFilter"
    :on-load="onLoad"
    :on-export="onExport"
  >
  </dashboard-chart>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { DashboardChart } from '../dashboards'
import service from '../../api/monthlyPlanningRevisedService'

export default defineComponent({
  name: 'MonthlyPlanningDashboardChartDemandNotFullfiledByRegion',
  components: {
    DashboardChart
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  methods: {
    async onLoad(chartReference) {
      const chart = await service.getDemandNotFullfiledByRegion(
        chartReference,
        this.baseFilter
      )
      return chart
    },
    async onExport(chartReference) {
      await service.getDemandNotFullfiledByRegionExcel(
        chartReference,
        this.baseFilter
      )
    }
  }
})
</script>
