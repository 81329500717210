<template>
  <bc-container class="monthly-planning-dashboard-revised">
    <cargill-section>
      <monthly-planning-dashboard-header
        :scenario="scenario"
        :unit="measurementUnit"
        @unit-change="onChangeMeasurementUnit"
      ></monthly-planning-dashboard-header>

      <cargill-section-content>
        <monthly-planning-dashboard-consolidated
          :scenario="scenario"
          :unit="measurementUnit"
        ></monthly-planning-dashboard-consolidated>

        <dashboard-date-filter
          v-model="selectedPeriod"
          :min-date="scenario.beginHorizon"
          :max-date="scenario.endHorizon"
        ></dashboard-date-filter>

        <div class="monthly-planning-dashboard-revised__charts">
          <bc-row dense>
            <bc-col>
              <monthly-planning-dashboard-chart-demand-fullfilment
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-demand-fullfilment>
            </bc-col>
          </bc-row>

          <bc-row dense>
            <bc-col cols="12" lg="6">
              <monthly-planning-dashboard-chart-demand-not-fullfiled-by-region
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-demand-not-fullfiled-by-region>
            </bc-col>

            <bc-col cols="12" lg="6">
              <monthly-planning-dashboard-chart-demand-not-fullfiled-by-us-bucket
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-demand-not-fullfiled-by-us-bucket>
            </bc-col>
          </bc-row>

          <bc-row dense>
            <bc-col>
              <monthly-planning-dashboard-chart-carrier-capacity-utilization
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-carrier-capacity-utilization>
            </bc-col>
          </bc-row>

          <bc-row dense>
            <bc-col cols="12" lg="6">
              <monthly-planning-dashboard-chart-origination-plan
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-origination-plan>
            </bc-col>

            <bc-col cols="12" lg="6">
              <monthly-planning-dashboard-chart-open-for-sales
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-open-for-sales>
            </bc-col>
          </bc-row>

          <bc-row dense>
            <bc-col cols="12" lg="4">
              <monthly-planning-dashboard-chart-warehouse-capacity-utilization
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-warehouse-capacity-utilization>
            </bc-col>

            <bc-col cols="12" lg="4">
              <monthly-planning-dashboard-chart-stuffing-utilization
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-stuffing-utilization>
            </bc-col>

            <bc-col cols="12" lg="4">
              <monthly-planning-dashboard-chart-chain-cost-projection
                :base-filter="baseFilter"
                :unit="measurementUnit"
              ></monthly-planning-dashboard-chart-chain-cost-projection>
            </bc-col>
          </bc-row>
        </div>
      </cargill-section-content>
    </cargill-section>
  </bc-container>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer } from '@brain/core'
import { useScenarioOpen, dashboardUtils } from '@cargill/shared'

import {
  CargillSection,
  CargillSectionContent
} from '../components/cargill-section'
import { DashboardDateFilter } from '../components/dashboards'
import {
  MonthlyPlanningDashboardHeader,
  MonthlyPlanningDashboardConsolidated,
  MonthlyPlanningDashboardChartOpenForSales,
  MonthlyPlanningDashboardChartOriginationPlan,
  MonthlyPlanningDashboardChartDemandFullfilment,
  MonthlyPlanningDashboardChartChainCostProjection,
  MonthlyPlanningDashboardChartStuffingUtilization,
  MonthlyPlanningDashboardChartDemandNotFullfiledByRegion,
  MonthlyPlanningDashboardChartCarrierCapacityUtilization,
  MonthlyPlanningDashboardChartDemandNotFullfiledByUsBucket,
  MonthlyPlanningDashboardChartWarehouseCapacityUtilization
} from '../components/monthly-planning-dashboard'

export default defineComponent({
  name: 'MonthlyPlanningDashboardRevised',
  components: {
    BcContainer,
    CargillSection,
    CargillSectionContent,
    DashboardDateFilter,
    MonthlyPlanningDashboardHeader,
    MonthlyPlanningDashboardConsolidated,
    MonthlyPlanningDashboardChartOpenForSales,
    MonthlyPlanningDashboardChartOriginationPlan,
    MonthlyPlanningDashboardChartDemandFullfilment,
    MonthlyPlanningDashboardChartChainCostProjection,
    MonthlyPlanningDashboardChartStuffingUtilization,
    MonthlyPlanningDashboardChartDemandNotFullfiledByRegion,
    MonthlyPlanningDashboardChartCarrierCapacityUtilization,
    MonthlyPlanningDashboardChartDemandNotFullfiledByUsBucket,
    MonthlyPlanningDashboardChartWarehouseCapacityUtilization
  },
  data() {
    return {
      scenarioOpen: useScenarioOpen(),
      measurementUnit: dashboardUtils.getUnityTonne(),
      selectedPeriod: {
        start: undefined,
        end: undefined
      }
    }
  },
  created() {
    this.resetFilter()
  },
  methods: {
    resetFilter() {
      this.selectedPeriod = {
        start: this.scenario.beginHorizon,
        end: this.scenario.endHorizon
      }
    },
    onChangeMeasurementUnit(newValue) {
      this.measurementUnit = newValue
    }
  },
  computed: {
    scenario() {
      return this.scenarioOpen.getScenarioOpen()
    },
    baseFilter() {
      return {
        startDate: this.selectedPeriod.start,
        endDate: this.selectedPeriod.end,
        unity: this.measurementUnit.value
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.monthly-planning-dashboard-revised {
  &__charts {
    margin-top: 10px;
  }
}
</style>
