import { api, dashboardUtils } from '@cargill/shared'

const baseURL = '/api/monthlyPlanning'

const service = {}

service.getConsolidated = async (filter) => {
  const url = `${baseURL}/consolidated`
  return await dashboardUtils.filterDashboard(filter, url)
}

service.getDemandFullfilment = async (oldChart, baseFilter) => {
  const url = `${baseURL}/demandFullfilment`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getDemandNotFullfiledByRegion = async (oldChart, baseFilter) => {
  const url = `${baseURL}/demandNotFullfiledByRegion`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getDemandNotFullfiledByCotton = async (oldChart, baseFilter) => {
  const url = `${baseURL}/demandNotFullfiledByCotton`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getDemandNotFullfiledByCottonTableMeta = async () => {
  const response = await api.get(
    `${baseURL}/demandNotFullfiledByCottonTableMeta`
  )
  return response.data
}

service.getDemandNotFullfiledByCottonTable = async (filter) => {
  const url = `${baseURL}/demandNotFullfiledByCottonTable`
  return await dashboardUtils.filterDashboard(filter, url)
}

service.getCarrierCapacityUtilization = async (oldChart, baseFilter) => {
  const url = `${baseURL}/carrierCapacityUtilization`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getOriginationPlan = async (oldChart, baseFilter) => {
  const url = `${baseURL}/originationPlan`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getOpenForSales = async (oldChart, baseFilter) => {
  const url = `${baseURL}/openForSales`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getWarehouseCapacityUtilization = async (oldChart, baseFilter) => {
  const url = `${baseURL}/warehouseCapacityUtilization`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getTakeOrPayUtilizationProjection = async (oldChart, baseFilter) => {
  const url = `${baseURL}/takeOrPayUtilizationProjection`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getChainCostProjection = async (oldChart, baseFilter) => {
  const url = `${baseURL}/chainCostProjection`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getChainCostProjectionByMonth = async (oldChart, baseFilter) => {
  const url = `${baseURL}/chainCostProjectionByMonth`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}

service.getDemandFullfilmentExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/demandFullfilmentExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getDemandNotFullfiledByRegionExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/demandNotFullfiledByRegionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getDemandNotFullfiledByCottonExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/demandNotFullfiledByCottonExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getCarrierCapacityUtilizationExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/carrierCapacityUtilizationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getOriginationPlanExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/originationPlanExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getOpenForSalesExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/openForSalesExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getWarehouseCapacityUtilizationExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/warehouseCapacityUtilizationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getTakeOrPayUtilizationProjectionExcel = async (
  chartRef,
  baseFilter
) => {
  const url = `${baseURL}/takeOrPayUtilizationProjectionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

service.getChainCostProjectionExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/chainCostProjectionExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}

export default service
