import { dashboardUtils } from '@cargill/shared'

const baseURL = '/api/monthlyPlanning'

const service = {
  getConsolidated: async (filter) => {
    const url = `${baseURL}/consolidated`
    return await dashboardUtils.filterDashboard(filter, url)
  },

  getDemandFullfilment: async (oldChart, baseFilter) => {
    const url = `${baseURL}/demandFullfilment`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getDemandFullfilmentExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/demandFullfilmentExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getDemandNotFullfiledByRegion: async (oldChart, baseFilter) => {
    const url = `${baseURL}/demandNotFullfiledByRegion`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getDemandNotFullfiledByRegionExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/demandNotFullfiledByRegionExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getDemandNotFullfiledByUsBucket: async (oldChart, baseFilter) => {
    const url = `${baseURL}/demandNotFullfiledByCotton`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getDemandNotFullfiledByUsBucketExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/demandNotFullfiledByCottonExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getCarrierCapacityUtilization: async (oldChart, baseFilter) => {
    const url = `${baseURL}/carrierCapacityUtilization`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getCarrierCapacityUtilizationExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/carrierCapacityUtilizationExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getOriginationPlan: async (oldChart, baseFilter) => {
    const url = `${baseURL}/originationPlan`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getOriginationPlanExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/originationPlanExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getOpenForSales: async (oldChart, baseFilter) => {
    const url = `${baseURL}/openForSales`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getOpenForSalesExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/openForSalesExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getWarehouseCapacityUtilization: async (oldChart, baseFilter) => {
    const url = `${baseURL}/warehouseCapacityUtilization`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getWarehouseCapacityUtilizationExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/warehouseCapacityUtilizationExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getStuffingUtilization: async (oldChart, baseFilter) => {
    const url = `${baseURL}/stuffingCapacityUtilization`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getStuffingUtilizationExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/stuffingCapacityUtilizationExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getChainCostProjection: async (oldChart, baseFilter) => {
    const url = `${baseURL}/chainCostProjection`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  },
  getChainCostProjectionExcel: async (chartRef, baseFilter) => {
    const url = `${baseURL}/chainCostProjectionExcel`
    return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
  },

  getChainCostProjectionByMonth: async (oldChart, baseFilter) => {
    const url = `${baseURL}/chainCostProjectionByMonth`
    return await dashboardUtils.filterChart(oldChart, baseFilter, url)
  }
}

export default service
